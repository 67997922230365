<template>
  <div>
    <v-overlay :value="overlay"
               color="white"
               opacity="0.7"
               z-index="9999">
      <v-progress-circular
              color="primary"
              indeterminate
              size="50"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
    name: "Overlay",
    props: ['overlay']
}
</script>

<style scoped>

</style>