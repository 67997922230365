<template>
  <div id="app">
    <v-app>

      <Navigation v-if="isLoggedIn"/>

      <v-main>

        <ErrorMessage/>
        <SuccessMessage/>

        <router-view/>

        <v-btn v-show="fab"
               v-scroll="onScroll"
               bottom
               color="secondary"
               dark
               fab
               fixed
               right
               @click="toTop">
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </v-main>
      <Footer v-if="isLoggedIn"/>
    </v-app>
  </div>
</template>
<script>
import Navigation from "@/components/navigation/Main.vue";
import ErrorMessage from "@/components/messages/ErrorMessage.vue";
import SuccessMessage from "@/components/messages/SuccessMessage";
import Footer from "@/components/navigation/Footer";

export default {
    components: {Footer, SuccessMessage, ErrorMessage, Navigation},
    data() {
        return {
            fab: false,
            isLoggedIn: this.$store.state.isLoggedIn,
        }
    },
    methods: {
        onScroll(e) {
            if (typeof window === 'undefined') return
            const top = window.pageYOffset || e.target.scrollTop || 0
            this.fab = top > 20
        },
        toTop() {
            this.$vuetify.goTo(0)
        },
    },
    created() {
        console.log(
            "%cWelcome To My Coca Cola",
            "color:#F40009;font-family:system-ui;font-size:3rem;-webkit-text-stroke: 1px black;font-weight:bold"
        );
    },
}
</script>

