<template>
  <div style="display: contents">
    <svg class="duoicon duoicon-clipboard" height="66" style="color: #F40009" viewBox="0 0 24 24" width="66"
         xmlns="http://www.w3.org/2000/svg">
      <path class="duoicon-secondary-layer"
            d="M7 3v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V3h1a2 2 0 0 1 2 2v11a6 6 0 0 1-6 6H6a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h1Z"
            fill="currentColor" opacity=".3"></path>
      <path class="duoicon-primary-layer"
            d="M14 2a1 1 0 0 1 .117 1.993L14 4h-4a1 1 0 0 1-.117-1.993L10 2h4Zm1 8H9a1 1 0 0 0-.117 1.993L9 12h6a1 1 0 1 0 0-2Zm-3 4H9a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2Z"
            fill="currentColor"></path>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>