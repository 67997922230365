import Vue from "vue";

let currantData = new Vue({
    data: {
        $baseUrl: process.env.VUE_APP_BASE_URL,
        $successMessage: '',
        $errorMessage: '',
    },
});
Vue.mixin({
    computed: {
        $baseUrl: {
            get: function () {
                return currantData.$data.$baseUrl
            },
            set: function (data) {
                currantData.$data.$baseUrl = data;
            }
        },
        $successMessage: {
            get: function () {
                return currantData.$data.$successMessage
            },
            set: function (data) {
                currantData.$data.$successMessage = data;
            }
        },
        $errorMessage: {
            get: function () {
                return currantData.$data.$errorMessage
            },
            set: function (data) {
                currantData.$data.$errorMessage = data;
            }
        },
    }
});