<template>
  <div style="display: contents">
    <svg class="duoicon duoicon-dashboard" height="66" style="color:#F40009" viewBox="0 0 24 24" width="66"
         xmlns="http://www.w3.org/2000/svg">
      <path class="duoicon-secondary-layer"
            d="M19 11a2 2 0 0 1 1.995 1.85L21 13v6a2 2 0 0 1-1.85 1.995L19 21h-4a2 2 0 0 1-1.995-1.85L13 19v-6a2 2 0 0 1 1.85-1.995L15 11h4Zm0-8a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4Z"
            fill="currentColor"
            fill-rule="evenodd" opacity=".3"></path>
      <path class="duoicon-primary-layer" d="M9 3a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4Z"
            fill="currentColor"
            fill-rule="evenodd"></path>
      <path class="duoicon-secondary-layer" d="M9 15a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h4Z"
            fill="currentColor" fill-rule="evenodd"
            opacity=".3"></path>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>