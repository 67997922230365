<template>
  <div>
    <v-footer  color="#eef2f6">
      <v-col class="text-right" cols="12">
        Copyright ©  {{ (new Date()).getFullYear() }}
        <a target="_blank" class="text-decoration-none" href="https://STORE-EXECUTION-dz.com">Store Execution</a>, All rights Reserved
      </v-col>
    </v-footer>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>