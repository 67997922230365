<template>
  <div>
    <v-snackbar :value="$successMessage"
                color="success"
                top right timeout="5000">
      <v-icon left color="white">mdi-check-circle</v-icon>
      <span v-html="$successMessage"></span>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  watch: {
    '$successMessage': function (el) {
      if (el) {
        let _vm = this
        setTimeout(function () {
          _vm.$successMessage = null
        }, 5000)
      }
    }
  },
}
</script>

<style scoped>

</style>