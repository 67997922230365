<template>
  <div>

    <!--**
    **Desktop**
    **-->
    <v-navigation-drawer :mini-variant="miniVariant"
                         app
                         floating
                         mini-variant-width="80">

      <vue-scroll :ops="ops">
        <div @mouseleave="mouseLeave" @mouseover="mouseOver">
          <Sidebar :mini-variant="miniVariant"/>
        </div>
      </vue-scroll>
    </v-navigation-drawer>


    <Navbar @miniVariant="miniVariant = !miniVariant" @mobileDrawer="mobileDrawer = !mobileDrawer"/>

    <!--**
    **Mobile**
    **-->
    <v-navigation-drawer v-model="mobileDrawer"
                         floating
                         app>

      <vue-scroll :ops="ops">
        <div @mouseleave="mouseLeave" @mouseover="mouseOver">
          <Sidebar :mini-variant="false"/>
        </div>
      </vue-scroll>

    </v-navigation-drawer>


  </div>
</template>

<script>
import Sidebar from "@/components/navigation/sidebar/index.vue";
import Navbar from "@/components/navigation/Navbar.vue";

export default {
    components: {
        Sidebar,
        Navbar
    },
    data() {
        return {
            ops: {
                bar: {
                    opacity: 0.6,
                    background: "#d0d0d5",
                    size: '6px',
                    keepShow: false,

                },
                scrollPanel: {
                    scrollingX: false,
                }
            },
            mobileDrawer: false,
            miniVariant: false
        }
    },
    methods: {
        mouseOver() {
            this.ops.bar.keepShow = true
        },
        mouseLeave() {
            this.ops.bar.keepShow = false
        }
    }
}
</script>

<style>

</style>