<template>
  <div>


    <v-list class="sidebar" flat nav>

      <v-list-item-group :class="miniVariant ? 'pa-2' :  'pa-3'" color="primary">

        <v-list-item :ripple="false" active-class="item--active font-weight-medium" to="/">
          <v-list-item-avatar size="24" tile>
            <DashboardIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Accueil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :ripple="false" active-class="item--active font-weight-medium" to="/campaigns">
          <v-list-item-avatar size="24" tile>
            <CampaignIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Campagnes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item :ripple="false" active-class="item--active font-weight-medium" to="/database">
          <v-list-item-avatar size="24" tile>
            <DatabaseIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Bases de données</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item :ripple="false" active-class="item--active font-weight-medium" to="/consents">
          <v-list-item-avatar size="24" tile>
            <ConsentIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Consentements</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item :ripple="false" active-class="item--active font-weight-medium" to="/check-sms">
          <v-list-item-avatar size="24" tile>
            <UploadFileIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Vérifier les sms</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :ripple="false" active-class="item--active font-weight-medium" to="/account">
          <v-list-item-avatar size="24" tile>
            <AccountIcon/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Mon compte</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import CampaignIcon from "@/components/svg-icons/CampaignIcon.vue";
import DashboardIcon from "@/components/svg-icons/DashboardIcon.vue";
import AccountIcon from "@/components/svg-icons/AccountIcon.vue";
import DatabaseIcon from "@/components/svg-icons/DatabaseIcon.vue";
import ConsentIcon from "@/components/svg-icons/ConsentIcon.vue";
import UploadFileIcon from "@/components/svg-icons/UploadFileIcon.vue";

export default {
    props: ['miniVariant'],
    components: {
        UploadFileIcon,
        ConsentIcon,
        AccountIcon,
        DashboardIcon,
        CampaignIcon,
        DatabaseIcon
    }
}
</script>

<style scoped>

</style>