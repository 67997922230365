<template>
  <div style="display: contents">
    <svg class="duoicon duoicon-coin-stack" height="66" style="color: #F40009" viewBox="0 0 24 24" width="66"
         xmlns="http://www.w3.org/2000/svg">
      <path class="duoicon-secondary-layer"
            d="M21 16.143V17.5c0 .814-.381 1.51-.91 2.057-.523.542-1.233.984-2.032 1.334C16.456 21.591 14.314 22 12 22c-2.314 0-4.456-.408-6.058-1.109-.799-.35-1.509-.792-2.032-1.334-.485-.5-.845-1.128-.902-1.856L3 17.5v-1.357c.465.264.962.498 1.494.695 2.03.751 4.685 1.17 7.506 1.17 2.821 0 5.476-.419 7.506-1.17.399-.148.778-.316 1.139-.503l.355-.192ZM12 3c2.314 0 4.456.408 6.058 1.109.799.35 1.509.792 2.032 1.334.485.5.845 1.128.902 1.856L21 7.5v.748a8.315 8.315 0 0 1-2.188 1.214c-1.755.65-4.164 1.047-6.812 1.047-2.647 0-5.056-.397-6.812-1.047a8.27 8.27 0 0 1-1.905-1.006L3 8.248V7.5c0-.814.381-1.51.91-2.057.523-.542 1.233-.984 2.032-1.334C7.544 3.409 9.686 3 12 3Z"
            fill="currentColor" opacity=".3"></path>
      <path class="duoicon-primary-layer"
            d="M3 10.643c.465.264.962.498 1.494.695 2.03.751 4.685 1.17 7.506 1.17 2.821 0 5.476-.419 7.506-1.17A10.132 10.132 0 0 0 21 10.643v3.105a8.315 8.315 0 0 1-2.188 1.214c-1.755.65-4.164 1.047-6.812 1.047-2.647 0-5.056-.397-6.812-1.047A8.315 8.315 0 0 1 3 13.748v-3.105Z"
            fill="currentColor"></path>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>