<template>
  <div style="display: contents">
    <svg class="duoicon duoicon-upload-file" height="66" style="color: #F40009;" viewBox="0 0 24 24" width="66"
         xmlns="http://www.w3.org/2000/svg">
      <path class="duoicon-secondary-layer" d="M12 2v6.5a1.5 1.5 0 0 0 1.356 1.493L13.5 10H20v10a2 2 0 0 1-1.85 1.995L18 22H6a2 2 0 0 1-1.995-1.85L4 20V4a2 2 0 0 1 1.85-1.995L6 2h6Z"
            fill="currentColor"
            fill-rule="evenodd" opacity=".3"></path>
      <path class="duoicon-primary-layer" d="M14 2.043a2 2 0 0 1 .877.43l.123.113L19.414 7c.234.234.407.523.502.84l.04.16H14V2.043Zm-2.707 9.13-2.121 2.121a1 1 0 1 0 1.414 1.414l.414-.414V17a1 1 0 1 0 2 0v-2.706l.414.414a1 1 0 1 0 1.414-1.414l-2.12-2.121a1 1 0 0 0-1.415 0Z"
            fill="currentColor"
            fill-rule="evenodd"></path>
    </svg>
  </div>
</template>

<script>
export default {
    name: "UploadFileIcon"
}
</script>

<style scoped>

</style>