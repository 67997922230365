import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        user: {},
        isLoggedIn: false,
    },
    mutations: {
        user: (state, payload) => {
            state.user = payload
        },
        isLoggedIn: (state, payload) => {
            state.isLoggedIn = payload
        },
        UpdateUserProfile: (state, payload) => {
            state.user.photo = payload
        },
        UpdateUserInfo: (state, payload) => {
            state.user.name = payload.name
            state.user.email = payload.email
            state.user.phone = payload.phone
        }
    },
    actions: {
        user({commit}, payload) {
            commit("user", payload);
        },
        isLoggedIn({commit}, payload) {
            commit("isLoggedIn", payload);
        },
        UpdateUserProfile({commit}, payload) {
            commit("UpdateUserProfile", payload);
        },
        UpdateUserInfo({commit}, payload) {
            commit("UpdateUserInfo", payload);
        }
    },
})
export default store
