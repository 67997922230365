import Vue from 'vue'
import App from './App.vue'
import './mixin'
import './directives'
import './filters'
import vuetify from "./plugins/vuetify"
import router from "./routes"
import store from './store/index'
import {func} from './functions'
import fr from "apexcharts/dist/locales/fr.json"

import VueApexCharts from 'vue-apexcharts'
import vuescroll from 'vuescroll'
import NoResult from "@/components/NoResult.vue"
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue"
import Overlay from "@/components/Overlay.vue"

Vue.use(VueApexCharts)
window.Apex.chart = {
    fontFamily: "Poppins, sans-serif",
    locales: [fr],
    defaultLocale: 'fr'
}

Vue.component('apexchart', VueApexCharts)
Vue.component('NoResult', NoResult)
Vue.component('ConfirmDialog', ConfirmDialog)
Vue.component('Overlay', Overlay)

Vue.use(vuescroll);
Vue.prototype.$func = func
Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    store,
    router,
    vuetify,
}).$mount('#app')
