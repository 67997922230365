<template>
  <div>
    <v-container fluid>
      <v-card class="transparent" flat>
        <v-card-text class="text-center">

          <img :src="require('@/assets/no-data.svg')" :width="width ? width : 350" alt=""/>

          <h2 class="mb-4">
            La liste est vide !!
          </h2>
          <p>
            Aucun enregistrement correspondant trouvé.
          </p>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
    props: ['width'],
    name: 'NoResult'
}
</script>

<style scoped>

</style>