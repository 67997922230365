export default [
    {
        path: '*',
        name: 'notFound',
        component: () => import('../views/404.vue'),
        meta: {
            title: 'Page non trouvée'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/login.vue'),
        meta: {
            title: 'Connexion'
        }
    },
    {
        path: '/approval',
        name: 'approval',
        component: () => import('../views/approval.vue'),
        meta: {
            title: 'Approuvé'
        }
    },
    {
        path: '/',
        name: 'dashboard',
        component: () => import('../views/dashboard/index.vue'),
        meta: {
            title: 'Dashboard'
        }
    },
    {
        path: '/account',
        name: 'account',
        component: () => import('../views/account/index.vue'),
        meta: {
            title: 'Mon compte',
        }
    },
    {
        path: '/campaigns',
        name: 'campaigns',
        component: () => import('../views/campaigns/index.vue'),
        meta: {
            title: 'Campagnes',
        }
    },
    {
        path: '/campaigns/create',
        name: 'campaigns.create',
        component: () => import('../views/campaigns/create/index.vue'),
        meta: {
            title: 'Campagnes - Nouvelle campagne',
        }
    },
    {
        path: '/database',
        name: 'database',
        component: () => import('../views/database/index.vue'),
        meta: {
            title: 'Base de données',
        }
    },
    {
        path: '/database/:id/show',
        name: 'database.show',
        component: () => import('../views/database/show/index.vue'),
        meta: {
            title: 'Base de données',
        }
    },
    {
        path: '/database/global',
        name: 'database.global',
        component: () => import('../views/database/show/index.vue'),
        meta: {
            title: 'Base de données',
        }
    },
    {
        path: '/consents',
        name: 'consents',
        component: () => import('../views/consents/index.vue'),
        meta: {
            title: 'Consentements',
        }
    },
    {
        path: '/check-sms',
        name: 'checkSms',
        component: () => import('../views/check-sms.vue'),
        meta: {
            title: 'Vérifier les sms',
        }
    },
]