<template>
  <div>
    <AsideLogo :mini-variant="miniVariant"/>
    <Menu :mini-variant="miniVariant"/>
  </div>
</template>

<script>


import Menu from "@/components/navigation/sidebar/Menu.vue";
import AsideLogo from "@/components/navigation/sidebar/AsideLogo.vue";

export default {
    components: {AsideLogo, Menu},
    props: ['miniVariant'],
}
</script>

<style>

.sidebar .v-list-item__title, .btn-add .v-list-item__title {
    font-size: 0.88rem;
    font-weight: 500
}

.sidebar.v-list--nav .v-list-item {
    border-radius: 8px;
    transition: 0.3s;
}

.sidebar.v-list--nav .v-list-item:hover {
    border-radius: 8px;
    background-color: rgba(244, 0, 9, 0.07);
}

.sidebar .v-list-item:hover .v-list-item__title, .sidebar .v-list-item:hover .v-list-item__avatar {
    color: #F40009;
}

.sidebar .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #5d5c61;
}

.sidebar .item--active {
    background-color: rgba(244, 0, 9, 0.07);
}

.btn-grad {
    background-image: linear-gradient(to right, #F40009 0%, #b31217  51%, #F40009  100%);
    text-transform: uppercase;
    transition: 0.8s;
    background-size: 200% auto;
    display: block;
}

.btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
}
</style>